html, body {
  margin: 0;
  padding: 0;
  font-family: 'MerkurSansBlack', Arial, sans-serif;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #282c34 url("/loader.gif") center center no-repeat; */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}